import React from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
	display: flex;
	align-items: center;
`;

const LogoCentinelas = styled(CmtImage)`
	max-height: 70px;
	margin-right: 20px;
`;

const LogoReforestamos = styled(CmtImage)`
	max-height: 55px;
	margin-right: 20px;
`;

const FooterLogo = ({ ...props }) => {
	return (
		<Box className="pointer" {...props}>
			<StyledNavLink to="/">
				<LogoCentinelas
					src={'/images/logo-centinelas-rm-color.png'}
					alt="Logo Centinelas del Tiempo"
				/>
				<LogoReforestamos
					src={'/images/reforestamosmx.png'}
					alt="Logo Reforestamos México"
				/>
			</StyledNavLink>
		</Box>
	);
};

export default FooterLogo;
