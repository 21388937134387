import { createReducer } from 'utils';

import { SET_ACCESS_TOKEN, SET_AUTH_USER, SET_LOAD_USER, SET_LOG_OUT } from '../../actions';

const initialState = {
	loadUser: false,
	authUser: {
		userId: null,
		email: null,
		avatar: null,
		displayName: null,
		roleId: null,
		username: null,
	},
	token: {
		accessToken: null,
		refreshToken: null,
	},
};

const auth = createReducer(initialState, {
	[SET_ACCESS_TOKEN](state, action) {
		return {
			...state,
			token: {
				...state.token,
				accessToken: action.accessToken,
				refreshToken: action.refreshToken,
			},
		};
	},
	[SET_AUTH_USER](state, action) {
		return {
			...state,
			loadUser: true,
			authUser: {
				...state.authUser,
				userId: action.userId,
				email: action.email,
				username: action.username,
				avatar: action.avatar,
				displayName: action.displayName,
				roleId: action.roleId,
			},
		};
	},
	[SET_LOAD_USER](state, action) {
		return {
			...state,
			loadUser: action.loadUser,
		};
	},
	[SET_LOG_OUT](state) {
		return {
			...state,
			loadUser: false,
			authUser: {
				...state.authUser,
				userId: null,
				email: null,
				username: null,
				avatar: null,
				displayName: null,
				roleId: null,
			},
			token: {
				...state.token,
				accessToken: null,
				refreshToken: null,
			},
		};
	},
});

export default auth;
