import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageLoader from '../@jumbo/components/PageComponents/PageLoader';
import { getAccessToken, getAuthUser } from '../redux/selectors';
import lazyWithRetry from '../utils/lazyWithRetry';

const Dashboard = lazyWithRetry(() => import('./Dashboard'));
const Contests = lazyWithRetry(() => import('./Contests'));
const ContestsPhotographies = lazyWithRetry(() => import('./Contests/ContestsPhotographies'));
const ContestsStatistics = lazyWithRetry(() => import('./Contests/ContestsStatistics'));
const Login = lazyWithRetry(() => import('./Auth/Login'));
const LoginAsJudge = lazyWithRetry(() => import('./Auth/LoginAsJudge'));
const Register = lazyWithRetry(() => import('./Auth/Register'));
const RequestResetPassword = lazyWithRetry(() => import('./Auth/RequestResetPassword'));
const ConfirmEmailChange = lazyWithRetry(() => import('./Users/ConfirmEmailChange'));
const ConfirmNewEmail = lazyWithRetry(() => import('./Users/ConfirmNewEmail'));
const ResetPassword = lazyWithRetry(() => import('./Auth/ResetPassword'));
const VerifyAccount = lazyWithRetry(() => import('./Auth/VerifyAccount'));
const Profile = lazyWithRetry(() => import('./Profile'));
const Users = lazyWithRetry(() => import('./Users'));
const Admins = lazyWithRetry(() => import('./Admins'));
const Evaluation = lazyWithRetry(() => import('./Evaluation'));

const RestrictedRoute = () => {
	const { token } = useSelector(({ auth }) => auth);

	if (!token.accessToken) {
		return <Navigate to={'/iniciar-sesion'} />;
	}

	return <Outlet />;
};

const AdminRestrictedRoute = () => {
	const authUser = useSelector(getAuthUser);

	if (authUser.roleId !== 1) {
		return <Navigate to={'/panel'} />;
	}

	return <Outlet />;
};

const JudgeRestrictedRoute = () => {
	const authUser = useSelector(getAuthUser);

	if (authUser.roleId !== 3) {
		return <Navigate to={'/panel'} />;
	}

	return <Outlet />;
};

const AppRoutes = () => {
	const accessToken = useSelector(getAccessToken);
	const authUser = useSelector(getAuthUser);

	const location = useLocation();

	if (location.pathname === '' || location.pathname === '/') {
		if (authUser.roleId === 3) {
			return <Navigate to={'/evaluacion'} />;
		}
		return <Navigate to={'/panel'} />;
	}

	// If the user goes to any of these URLs and is authenticated we redirect to our Dashboard
	if (
		(accessToken && authUser.roleId && location.pathname === '/iniciar-sesion') ||
		(accessToken && authUser.roleId && location.pathname === '/iniciar-sesion-como-juez') ||
		(accessToken && authUser.roleId && location.pathname === '/registrarse') ||
		(accessToken && authUser.roleId && location.pathname === '/recuperar-contrasena')
	) {
		if (authUser.roleId === 3) {
			return <Navigate to={'/evaluacion'} />;
		}

		if (authUser.roleId === 1) {
			return <Navigate to={'/estadisticas'} />;
		}
		return <Navigate to={'/panel'} />;
	}

	return (
		<div style={{ flexGrow: 1, width: '100%', display: 'flex' }}>
			<Suspense fallback={<PageLoader />}>
				<Routes>
					<Route path="/verificar-cuenta" element={<VerifyAccount />} />
					<Route path="/iniciar-sesion" element={<Login />} />
					<Route path="/iniciar-sesion-como-juez" element={<LoginAsJudge />} />
					<Route path="/registrarse" element={<Register />} />
					<Route path="/recuperar-contrasena" element={<RequestResetPassword />} />
					<Route path="/restablecer-contrasena" element={<ResetPassword />} />

					<Route path="/confirmar-cambio-email" element={<ConfirmEmailChange />} />

					<Route path="/confirmar-nuevo-email" element={<ConfirmNewEmail />} />
					<Route element={<RestrictedRoute />}>
						<Route path="/panel" element={<Dashboard />} />
						<Route path="/user/:id" element={<Profile />} />
					</Route>

					<Route element={<AdminRestrictedRoute />}>
						<Route path="/estadisticas" element={<ContestsStatistics />} />
						<Route
							path="/concursos/:id/fotografias"
							element={<ContestsPhotographies />}
						/>
						<Route path="/concursos" element={<Contests />} />
						<Route path="/usuarios" element={<Users />} />
						<Route path="/administradores" element={<Admins />} />
					</Route>
					<Route element={<JudgeRestrictedRoute />}>
						<Route path="/evaluacion" element={<Evaluation />} />
					</Route>
				</Routes>
			</Suspense>
		</div>
	);
};

export default AppRoutes;
