import { makeActionCreator } from 'utils';

export const TRY_CREATE_PHOTOGRAPHY = 'TRY_CREATE_PHOTOGRAPHY';
export const tryCreatePhotography = makeActionCreator(
	TRY_CREATE_PHOTOGRAPHY,
	'photographyData',
	'resolve',
	'reject'
);

export const TRY_SET_USER_PHOTOGRAPHIES = 'TRY_SET_USER_PHOTOGRAPHIES';
export const trySetUserPhotographies = makeActionCreator(
	TRY_SET_USER_PHOTOGRAPHIES,
	'lastContestId'
);

export const SET_USER_PHOTOGRAPHIES = 'SET_USER_PHOTOGRAPHIES';
export const setUserPhotographies = makeActionCreator(
	SET_USER_PHOTOGRAPHIES,
	'items',
	'total',
	'categories',
);

export const TRY_DELETE_USER_PHOTOGRAPHY = 'TRY_DELETE_USER_PHOTOGRAPHY';
export const tryDeleteUserPhotography = makeActionCreator(
	TRY_DELETE_USER_PHOTOGRAPHY,
	'id',
	'categorySlug',
	'resolve',
	'reject'
);

export const SET_DELETE_USER_PHOTOGRAPHY = 'SET_DELETE_USER_PHOTOGRAPHY';
export const setDeleteUserPhotography = makeActionCreator(
	SET_DELETE_USER_PHOTOGRAPHY,
	'id',
	'categorySlug'
);
