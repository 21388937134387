import React from "react";
import {Provider} from "react-redux";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import configureStore from "./redux/store";
import AppWrapper from "./@jumbo/components/AppWrapper";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import Routes from "./routes";
import * as Sentry from "@sentry/react";
import { HistoryRouter as Router } from "redux-first-history/rr6";


export const {store, history} = configureStore();
const App = () => {
    return <Provider store={store}>
        <Router history={history}>
            <AppContextProvider>
                <AppWrapper>
                    <Routes />
                </AppWrapper>
            </AppContextProvider>
        </Router>
    </Provider>;
};

export default Sentry.withProfiler(App);
