import { combineReducers } from 'redux';

import common from './common';
import auth from './auth';
import userPhotographies from './userPhotographies';
import lastContest from './lastContest';
import user from './user';
import pagination from './pagination';
import judge from './judge';

const reducer = routerReducer => combineReducers({
	router: routerReducer,
	auth,
	common,
	userPhotographies,
	lastContest,
	user,
	pagination,
	judge,
});

export default reducer;
	
