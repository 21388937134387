import { makeActionCreator } from 'utils';

export const TRY_INITIAL_EVALUATION_SET = 'TRY_INITIAL_EVALUATION_SET';
export const tryInitialEvaluationSet = makeActionCreator(
    TRY_INITIAL_EVALUATION_SET
);

export const INITIAL_EVALUATION_SET = 'INITIAL_EVALUATION_SET';
export const initialEvaluationSet = makeActionCreator(
    INITIAL_EVALUATION_SET,
    'totalEvaluationItems',
    'scoreItemKeys',
);

export const UPDATE_EVALUATION_SCORE_KEY = 'UPDATE_EVALUATION_SCORE_KEY';
export const updateEvaluationScoreKey = makeActionCreator(
    UPDATE_EVALUATION_SCORE_KEY,
    'photographyId',
    'previousScore',
    'newScore'
);

export const TRY_SET_FILTERED_BY = 'TRY_SET_FILTERED_BY';
export const trySetFilteredBy = makeActionCreator(
    TRY_SET_FILTERED_BY,
    'filteredBy'
);

export const SET_FILTERED_BY = 'SET_FILTERED_BY';
export const setFilteredBy = makeActionCreator(
    SET_FILTERED_BY,
    'filteredBy'
);

export const TRY_UPDATE_PHOTOGRAPHY_SCORE = 'TRY_UPDATE_PHOTOGRAPHY_SCORE';
export const tryUpdatePhotographyScore = makeActionCreator(
    TRY_UPDATE_PHOTOGRAPHY_SCORE,
    'contestJudgePhotographyId',
    'photographyId',
    'previousScore',
    'newScore',
);

export const UPDATE_PAGINATION_PHOTOGRAPHY_SCORE = 'UPDATE_PAGINATION_PHOTOGRAPHY_SCORE';
export const updatePaginationPhotographyScore = makeActionCreator(
    UPDATE_PAGINATION_PHOTOGRAPHY_SCORE,
    'contestJudgePhotographyId',
    'score',
);