import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1300,
		color: '#fff'
	}
}));

const PageLoader = ({ open = true }) => {
	const classes = useStyles();

	return (
		<Backdrop open={open} className={classes.backdrop}>
			<CircularProgress size={50} thickness={4} />
		</Backdrop>
	);
};

export default PageLoader;
