import { createReducer } from 'utils';

import { SET_CLEAN_USER_PROFILE, SET_USER_PROFILE } from 'redux/actions';

const initialState = {
	firstName: null,
	lastName: null,
	phone: null,
	mobilePhone: null,
	externalNumber: null,
	internalNumber: null,
	postalCode: null,
	colonia: null,
	street: null,
	birthDate: null,
	email: null,
	username: null,
	gender: null,
	instagram: null,
	twitter: null,
	facebook: null,
	lastLogin: null,
	roleId: null,
	municipalityId: null,
	stateId: null,
	municipality: null,
	imTutor: null,
	minorName: null,
	verified: null,
	verifiedAt: null,
	createdAt: null,
	updatedAt: null,
};

export default createReducer(initialState, {
	[SET_USER_PROFILE](state, action) {
		return {
			...state,
			firstName: action.firstName,
			lastName: action.lastName,
			phone: action.phone,
			mobilePhone: action.mobilePhone,
			externalNumber: action.externalNumber,
			internalNumber: action.internalNumber,
			postalCode: action.postalCode,
			colonia: action.colonia,
			street: action.street,
			birthDate: action.birthDate,
			email: action.email,
			username: action.username,
			gender: action.gender,
			instagram: action.instagram,
			twitter: action.twitter,
			facebook: action.facebook,
			lastLogin: action.lastLogin,
			roleId: action.roleId,
			municipalityId: action.municipalityId,
			stateId: action.stateId,
			municipality: action.municipality,
			imTutor: action.imTutor,
			minorName: action.minorName,
			verified: action.verified,
			verifiedAt: action.verifiedAt,
			createdAt: action.createdAt,
			updatedAt: action.updatedAt,
		};
	},
	[SET_CLEAN_USER_PROFILE](state) {
		return {
			...state,
			firstName: null,
			lastName: null,
			phone: null,
			mobilePhone: null,
			externalNumber: null,
			internalNumber: null,
			postalCode: null,
			colonia: null,
			street: null,
			birthDate: null,
			email: null,
			username: null,
			gender: null,
			instagram: null,
			twitter: null,
			facebook: null,
			lastLogin: null,
			roleId: null,
			municipalityId: null,
			stateId: null,
			municipality: null,
			imTutor: null,
			minorName: null,
			verified: null,
			verifiedAt: null,
			createdAt: null,
			updatedAt: null,
		};
	},
});
