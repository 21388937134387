import {get, put} from '../api';

const Judge = {
    updatePassword: (judgeId, data, config) => put(`/judges/${judgeId}/updatePassword`, data, config),
    getPhotographs: (sort, page, pageSize, search, params, config) => {
        const {judgeId, filteredBy} = params;
        return get(
            `/judges/${judgeId}/photographs/?sort=${sort}&pageSize=${pageSize}&page=${page}&searchKey=${search}&fields=id,photography&filteredBy=${filteredBy}`,
            config
        )
    },
    readPhotography: (photographyId, config) => get(`/judges/photography/${photographyId}`, config),
    getStatistics: (config) => get(`/judges/statistics`, config),
    updateScore: (contestJudePhotographyId, score, config) => put(`/judges/updateScore`, {
        contestJudePhotographyId,
        score
    }, config),
};

export default Judge;