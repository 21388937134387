import {createSelector} from "reselect";

export const getPageList = createSelector(
	[
		state => state.pagination,
		(state, key) => key,
	],
	(pagination, key) => {
		let fetching = false;
		let resultsPerPage = [];
		const paginationObj = pagination.pagination;
		if (paginationObj[key].pages[paginationObj[key].currentPage]) {
			fetching =
				paginationObj[key].pages[paginationObj[key].currentPage].fetching;

			resultsPerPage = paginationObj[key].pages[
					paginationObj[key].currentPage
				].ids.map(id => ({
					...pagination[key][id],
					photography: {
						...pagination[key][id].photography,
					}
				}));
		}

		return {
			currentPage: paginationObj[key].currentPage,
			totalOfItems: paginationObj[key].totalOfItems,
			limitOfPages: paginationObj[key].limitOfPages,
			resultsPerPage,
			fetching
		};
	}
)

/*export const getPageList = (key) => ({ pagination }) => {
	let fetching = false;
	let resultsPerPage = [];
	const paginationObj = pagination.pagination;
	if (paginationObj[key].pages[paginationObj[key].currentPage]) {
		fetching =
			paginationObj[key].pages[paginationObj[key].currentPage].fetching;

		resultsPerPage = paginationObj[key].pages[
				paginationObj[key].currentPage
			].ids.map(id => pagination[key][id]);
	}

	return {
		currentPage: paginationObj[key].currentPage,
		totalOfItems: paginationObj[key].totalOfItems,
		limitOfPages: paginationObj[key].limitOfPages,
		resultsPerPage,
		fetching
	};
};*/
