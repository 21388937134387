import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchError, receivePage, REQUEST_PAGE } from 'redux/actions';

import { errorHandler } from 'utils/errorHandler';
import { getAccessToken } from 'redux/selectors';

import Contest from 'services/endpoints/Contest';
import Users from 'services/endpoints/User';
import Photography from '../../../services/endpoints/Photography';
import Judge from '../../../services/endpoints/Judge';

const requestService = key => {
	let endpoint;

	switch (key) {
		case 'contests':
			endpoint = Contest.search;
			break;
		case 'contestsPhotographies':
			endpoint = Contest.searchPhotographies;
			break;
		case 'users':
			endpoint = Users.search;
			break;
		case 'photographies':
			endpoint = Photography.search;
			break;
		case 'judgePhotographs':
			endpoint = Judge.getPhotographs;
			break;
		default:
			endpoint = {
				// eslint-disable-next-line no-unused-vars
				search: (sort, page, pageSize, search, params, config) => {},
			};
	}

	return endpoint;
};

function* requestPageSaga({ page, pageSize, search, key, params, sort = '-created_at' }) {
	const accessToken = yield select(getAccessToken);
	if (!accessToken) {
		return false;
	}

	const headerParams = {
		Authorization: `Bearer ${accessToken}`,
	};

	try {
		const response = yield call(() =>
			requestService(key)(sort, page, pageSize, search, params, {
				headers: headerParams,
			})
		);

		const responseInfo = yield call(() => response.data);

		yield put(
			receivePage(page, key, responseInfo.total, responseInfo.limit, responseInfo.items)
		);
	} catch (error) {
		const [errorMessage] = errorHandler(error);
		yield put(fetchError(errorMessage));
	}
}

export function* paginationSaga() {
	yield takeLatest(REQUEST_PAGE, requestPageSaga);
}
