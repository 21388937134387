import { makeActionCreator } from 'utils';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const setAccessToken = makeActionCreator(SET_ACCESS_TOKEN, 'accessToken', 'refreshToken');

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const setAuthUser = makeActionCreator(
	SET_AUTH_USER,
	'userId',
	'email',
	'username',
	'displayName',
	'avatar',
	'roleId'
);

export const TRY_AUTH_USER = 'TRY_AUTH_USER';
export const tryAuthUser = makeActionCreator(
	TRY_AUTH_USER,
	'email',
	'password',
	'resolve',
	'reject'
);

export const TRY_AUTH_USERNAME = 'TRY_AUTH_USERNAME';
export const tryAuthUserName = makeActionCreator(
	TRY_AUTH_USERNAME,
	'username',
	'password',
	'resolve',
	'reject'
);

export const VERIFY_AUTH_TOKEN = 'VERIFY_AUTH_TOKEN';
export const verifyAuthToken = makeActionCreator(VERIFY_AUTH_TOKEN);

export const SET_LOAD_USER = 'SET_LOAD_USER';
export const setLoadUser = makeActionCreator(SET_LOAD_USER, 'loadUser');

export const TRY_LOG_OUT = 'TRY_LOG_OUT';
export const tryLogOut = makeActionCreator(TRY_LOG_OUT);

export const SET_LOG_OUT = 'SET_LOG_OUT';
export const setLogOut = makeActionCreator(SET_LOG_OUT);

export const ON_REQUEST_RESET_PASSWORD = 'ON_REQUEST_RESET_PASSWORD';
export const onRequestResetPassword = makeActionCreator(
	ON_REQUEST_RESET_PASSWORD,
	'email',
	'resolve',
	'reject'
);

export const ON_RESET_PASSWORD = 'ON_RESET_PASSWORD';
export const onResetPassword = makeActionCreator(
	ON_RESET_PASSWORD,
	'recoveryToken',
	'newPassword',
	'newPasswordConfirmation',
	'resolve',
	'reject'
);

export const ON_VERIFY_ACCOUNT = 'ON_VERIFY_ACCOUNT';
export const onVerifyAccount = makeActionCreator(
	ON_VERIFY_ACCOUNT,
	'verificationToken',
	'resolve',
	'reject'
);
