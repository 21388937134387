import { createReducer } from 'utils';

import { SET_CLEAN_LAST_CONTEST, SET_LAST_CONTEST } from '../../actions';

const initialState = {
	categories: [],
	registrationStageOpenAt: null,
	registrationStageClosedAt: null,
	evaluationStageOpenAt: null,
	evaluationStageClosedAt: null,
	name: null,
	id: null
};

export default createReducer(initialState, {
	[SET_LAST_CONTEST](state, action) {
		return {
			...state,
			categories: action.categories,
			registrationStageOpenAt: action.registrationStageOpenAt,
			registrationStageClosedAt: action.registrationStageClosedAt,
			evaluationStageOpenAt: action.evaluationStageOpenAt,
			evaluationStageClosedAt: action.evaluationStageClosedAt,
			name: action.name,
			id: action.id
		};
	},
	[SET_CLEAN_LAST_CONTEST](state) {
		return {
			...state,
			registrationStageOpenAt: null,
			registrationStageClosedAt: null,
			evaluationStageOpenAt: null,
			evaluationStageClosedAt: null,
			name: null,
			id: null
		};
	}
});
