import React, { useContext, useMemo } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';

import { IntlProvider } from 'react-intl';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';

import AppContext from '../contextProvider/AppContextProvider/AppContext';
import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import { esES } from '@mui/x-data-grid';

const AppWrapper = ({ children }) => {
	const { theme } = useContext(AppContext);

	const muiTheme = useMemo(() => {
		return createTheme(theme, esES);
	}, [theme]);

	return (
        <IntlProvider
			locale={"es"}
			messages={AppLocale["es"].messages}
		>
			<StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        <SnackbarProvider maxSnack={3}>
                            <AppLayout>{children}</AppLayout>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
		</IntlProvider>
    );
};

export default AppWrapper;
