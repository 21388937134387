import {createReducer} from 'utils';
import {INITIAL_EVALUATION_SET, SET_FILTERED_BY, UPDATE_EVALUATION_SCORE_KEY} from "../../actions";

const initialState = {
    totalEvaluationItems: 0,
    scoreItemKeys: {
        pending: [],
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: []
    },
    filteredBy: "all"
};

export default createReducer(initialState, {
    [INITIAL_EVALUATION_SET](state, action) {
        const {scoreItemKeys} = action;
        const {null: pending, ...rest} = scoreItemKeys;
        return {
            ...state,
            totalEvaluationItems: action.totalEvaluationItems,
            scoreItemKeys: {
                ...state.scoreItemKeys,
                ...rest,
                pending: pending ? [...pending] : []
            }
        };
    },
    [UPDATE_EVALUATION_SCORE_KEY](state, action) {
        const {photographyId, previousScore, newScore} = action;

        return {
            ...state,
            scoreItemKeys: {
                ...state.scoreItemKeys,
                [previousScore]: state.scoreItemKeys[previousScore].filter(id => id !== photographyId),
                [newScore]: [
                    ...state.scoreItemKeys[newScore],
                    photographyId
                ]
            }
        }
    },
    [SET_FILTERED_BY](state, action) {
        return {
            ...state,
            filteredBy: action.filteredBy,
        }
    }
});