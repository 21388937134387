import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel({ value, label }) {
	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress
				size={80}
				variant={label ? 'indeterminate' : 'determinate'}
				disableShrink={!!label}
				thickness={4}
				value={value}
			/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				flexWrap="wrap"
			>
				{!label && (
					<Typography
						variant="caption"
						component="div"
						color="textSecondary"
					>{`${Math.round(value)}%`}</Typography>
				)}
				{!label && (
					<Typography
						variant="caption"
						component="div"
						color="textSecondary"
					>
						Cargado
					</Typography>
				)}
				{label && (
					<Typography
						variant="caption"
						component="div"
						color="textSecondary"
					>
						{label}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

export default CircularProgressWithLabel;
