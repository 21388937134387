import { post } from '../api';

const Auth = {
	login: data => post('/authenticate', data),
	refreshToken: data => post('/refresh-token', data),
	verifyAuthUser: config => post('/me', '', config),
	requestResetPassword: data => post('/request_reset_password', data),
	resetPassword: data => post('/reset_password', data),
	verifyAccount: data => post('/verify_account', data),
	doNotAcceptTermsAndConditions: data => post('/do-not-accept-terms', data),
	resendAcceptTermsEmail: data => post('/resend_accept_terms_email', data),
};

export default Auth;
