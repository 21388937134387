import {destroy, get, post, put} from '../api';

const User = {
	getUsers: config => get('/users', config),
	create: data => post('/users', data),
	createUserAsAdmin: (data, config) => post('/users/createAsAdmin', data, config),
	delete: (userId, config) => destroy(`/users/${userId}`, config),
	read: (userId, config) => get(`/users/${userId}`, config),
	search: (sort, page, pageSize, search, params, config) => {
		const {roleId} = params;
		return get(
			`/users/?sort=${sort}&pageSize=${pageSize}&page=${page}&searchKey=${search}&roleId=${roleId}`,
			config
		);
	},
	update: (userId, data, config) => put(`/users/${userId}`, data, config),
	updateAsAdmin: (userId, data, config) => put(`/users/updateAsAdmin/${userId}`, data, config),
	getPhotographies: (userId, contestId, config) =>
		get(`/users/${userId}/${contestId}/photographies`, config),
	confirmEmailChange: (data, config) => post(`/users/confirm-email-change`, data, config),
	confirmNewEmail: (data, config) => post(`/users/confirm-new-email`, data, config),
};

export default User;
