import React, {useContext} from "react";
import clsx from "clsx";
import {List} from "@mui/material";
import SidebarThemeContext from "../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";
import { makeStyles } from '@mui/styles';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import styled from "styled-components";
import Favorite from "@mui/icons-material/Favorite";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Equalizer from "@mui/icons-material/Equalizer";
import Rating from "@mui/lab/Rating";
import {useDispatch, useSelector} from "react-redux";
import {getJudgeStatistics} from "../../redux/selectors/getJudgeTotalPhotos";
import CustomListItem from "./CustomListItem";
import {trySetFilteredBy} from "../../redux/actions";
import {getFilteredBy} from "../../redux/selectors";
import {Star} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    navHeader: {
        borderTop: props => `solid 1px ${props.sidebarTheme.borderColor}`,
        position: 'relative',
        marginTop: '24px',
        padding: '24px 16px 20px 16px',
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 10,
        letterSpacing: 1.5,
        '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
            display: 'none',
        },
    },
    navSection: {
        position: 'relative',
        fontWeight: theme.typography.fontWeightRegular,
        '&:not(:first-child) .Cmt-navHeader': {
            borderTop: props => `solid 1px ${props.sidebarTheme.borderColor}`,
            marginTop: 10,
        },
        '&:not(:last-child)': {
            '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
                borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
                paddingTop: 10,
                paddingBottom: 10,
            },
        },
    },
    listIcon: {
        minWidth: "30px",
    },
    listUl: {
        padding: 0,
    }
}));
const StyledRatingContainer = styled.div`
  padding: 0 16px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SideBarFilters = () => {
    const dispatch = useDispatch();
    const filteredBy = useSelector(getFilteredBy);
    const { sidebarTheme } = useContext(SidebarThemeContext);
    const classes = useStyles({ sidebarTheme });
    const judgeStatistics = useSelector(getJudgeStatistics);
    const {pending, total, 0: review, ...rest} = judgeStatistics;
    const statisticsStarsQuantity = Object.keys(rest).includes(`${filteredBy}`) ? rest[filteredBy].length : "-";

    const setFilteredBy = (filter) => {
        dispatch(trySetFilteredBy(filter));
    };

    const handleFilterRating = (event, value) => {
        if (value || value === 0) {
            setFilteredBy(value)
        }
    };

    return <React.Fragment>
        <List component="div" className={clsx(classes.navHeader, 'Cmt-navHeader')}>
            Filtrar por
        </List>
        <List className={classes.listUl}>
            <CustomListItem
                active={filteredBy === 'all'}
                onClick={() => setFilteredBy('all')}
                icon={<PhotoCamera style={{
                    color: "#147aff"
                }} />}
                label="Todas las fotografías"
                quantity={total}
            />
            <CustomListItem
                active={filteredBy === 'best'}
                onClick={() => setFilteredBy('best')}
                icon={<Star style={{
                    color: "#ffb400"
                }} />}
                label="Mejor calificadas con 5 puntos"
                quantity={rest[5].length}
            />
            <CustomListItem
                active={filteredBy === 'pending'}
                onClick={() => setFilteredBy('pending')}
                icon={<HourglassEmptyIcon style={{
                    color: "#fa4646"
                }} />}
                label="Pendientes por calificar"
                quantity={pending.length}
            />
            <CustomListItem
                active={filteredBy === 0}
                onClick={() => setFilteredBy(0)}
                icon={<Favorite style={{
                    color: "#ff0000"
                }} />}
                label="Pendientes por revisar"
                quantity={review.length}
            />
            <CustomListItem
                active={[1, 2, 3, 4, 5].includes(filteredBy)}
                icon={<Equalizer style={{
                    color: "#6d4c41"
                }} />}
                label="Calificación"
                quantity={statisticsStarsQuantity}
            >
                <StyledRatingContainer>
                    <Rating
                        size="medium"
                        name={`statistics-stars`}
                        onChange={handleFilterRating}
                        value={Number.isInteger(filteredBy) ? filteredBy : null }
                        max={5}
                    />
                </StyledRatingContainer>
            </CustomListItem>
        </List>
        </React.Fragment>
};

export default SideBarFilters;