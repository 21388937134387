import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import { alpha } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { useTheme } from '@mui/material/styles';

import { composeValidators, required, isEmail } from 'utils/validation';
import { getAuthUser } from 'redux/selectors';

import CustomField from 'components/CustomField/CustomField';
import { tryUpdateUserProfile } from 'redux/actions';

const useStyles = makeStyles(theme => ({
	title: {
		'& .MuiTypography-root': {
			fontSize: 20,
		},
	},
	textFieldRoot: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: alpha(theme.palette.common.dark, 0.12),
		},
	},
}));

const ChangeEmailDialog = ({ open, handleClose }) => {
	const authUser = useSelector(getAuthUser);
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const onSubmit = async (values, form) => {
		try {
			await new Promise((resolve, reject) =>
				dispatch(tryUpdateUserProfile(authUser.userId, values, resolve, reject))
			);

			form.restart();
			handleClose();
		} catch (errors) {
			if (errors) {
				return errors;
			}
		}
	};

	return (
		open && (
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				fullScreen={fullScreen}
				maxWidth="sm"
				fullWidth
			>
				<DialogTitle className={classes.title} id="form-dialog-title">
					Cambiar Email
				</DialogTitle>

				<Form
					onSubmit={onSubmit}
					render={({ handleSubmit, submitting, pristine }) => (
						<form onSubmit={handleSubmit}>
							<DialogContent>
								<Box>
									<Field
										name="email"
										validate={composeValidators(required, isEmail)}
									>
										{props => (
											<CustomField
												label="Nuevo Email"
												fullWidth
												margin="normal"
												variant="outlined"
												className={classes.textFieldRoot}
												{...props}
											/>
										)}
									</Field>
								</Box>
							</DialogContent>

							<DialogActions>
								<Button onClick={handleClose} variant="contained" color="secondary">
									Cancelar
								</Button>
								<Button
									type="submit"
									variant="contained"
									disabled={pristine || submitting}
									color="primary"
								>
									Modificar
								</Button>
							</DialogActions>
						</form>
					)}
				/>
			</Dialog>
		)
	);
};

export default ChangeEmailDialog;
