/* eslint-disable no-undef */
import axios from 'axios';

/**
 * Temporal imports
 */

const apiClient = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? 'https://api.centinelasdeltiempo.org/'
			: 'http://localhost:9000',
});

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
