import { makeActionCreator } from 'utils';

export const REQUEST_PAGE = 'REQUEST_PAGE';
export const requestPage = makeActionCreator(
	REQUEST_PAGE,
	'page',
	'pageSize',
	'search',
	'key',
	'params',
	'sort',
);

export const RECEIVE_PAGE = 'RECEIVE_PAGE';
export const receivePage = makeActionCreator(
	RECEIVE_PAGE,
	'page',
	'key',
	'total',
	'limit',
	'items'
);
