import { call, put, takeLatest, select } from 'redux-saga/effects';

import { getAccessToken } from 'redux/selectors';

import {
	setAccessToken,
	setAuthUser,
	TRY_AUTH_USER,
	VERIFY_AUTH_TOKEN,
	TRY_LOG_OUT,
	setLogOut,
	fetchStart,
	fetchSuccess,
	fetchError,
	setLoadUser,
	ON_RESET_PASSWORD,
	ON_REQUEST_RESET_PASSWORD,
	ON_VERIFY_ACCOUNT,
	setLastContest,
	TRY_AUTH_USERNAME,
} from 'redux/actions';
import { errorHandler } from 'utils/errorHandler';
import assetService from 'services/assetService';

import Auth from 'services/endpoints/Auth';

function* tryAuthUser({ resolve, reject, ...data }) {
	yield put(fetchStart());
	try {
		const response = yield call(() => Auth.login(data));
		const responseInfo = yield call(() => response.data);

		if (responseInfo.success) {
			const { accessToken, refreshToken, user, lastContest } = responseInfo;
			const authUser = user;

			yield put(setAccessToken(accessToken, refreshToken));
			yield put(
				setAuthUser(
					authUser.id,
					authUser.email,
					authUser.username,
					`${authUser.firstName} ${authUser.lastName}`,
					'',
					authUser.roleId
				)
			);

			yield put(
				setLastContest(
					lastContest.registrationStageOpenAt,
					lastContest.registrationStageClosedAt,
					lastContest.evaluationStageOpenAt,
					lastContest.evaluationStageClosedAt,
					lastContest.name,
					lastContest.id
				)
			);
			yield put(fetchSuccess(assetService.sagas.success.welcomeMessage));

			yield call(resolve, assetService.sagas.success.welcomeMessage);
		}
	} catch (error) {
		const [errorMessage] = errorHandler(error);
		yield put(fetchError(errorMessage));
		yield call(reject, error);
	}
}

function* verifyAuthUser() {
	const accessToken = yield select(getAccessToken);
	if (!accessToken) {
		yield put(setLoadUser(true));
		return false;
	}
	const headerParams = {
		Authorization: `Bearer ${accessToken}`,
	};

	try {
		yield call(() =>
			Auth.verifyAuthUser({
				headers: headerParams,
			})
		);
		// const responseInfo = yield call(() => response.data);
	} catch (error) {
		const [errorMessage] = errorHandler(error);
		yield put(fetchError(errorMessage));
		yield put(setLogOut());
		yield put(setLoadUser(true));
	}
}

function* logOut() {
	yield put(fetchStart());
	yield put(setLogOut());
	yield put(setLoadUser(true));
	yield put(fetchSuccess());
}

function* requestResetPassword({ email, resolve, reject }) {
	yield put(fetchStart());

	try {
		const response = yield call(() =>
			Auth.requestResetPassword({
				email,
			})
		);

		const responseInfo = yield call(() => response.data);

		if (responseInfo.success) {
			yield call(resolve, responseInfo.message);
		} else {
			yield call(resolve, null);
		}
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage, errors] = errorHandler(error);
		yield call(reject, errors);
		yield put(fetchError(errorMessage));
	}
}

function* resetPassword({ recoveryToken, newPassword, newPasswordConfirmation, resolve, reject }) {
	yield put(fetchStart());

	try {
		const response = yield call(() =>
			Auth.resetPassword({
				recoveryToken,
				newPassword,
				newPasswordConfirmation,
			})
		);

		const responseInfo = yield call(() => response.data);

		if (responseInfo.success) {
			yield call(resolve, responseInfo.message);
		} else {
			yield call(resolve, null);
		}
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage, errors] = errorHandler(error);
		yield call(reject, errors);
		yield put(fetchError(errorMessage));
	}
}

function* verifyAccount({ verificationToken, resolve, reject }) {
	yield put(fetchStart());

	try {
		const response = yield call(() =>
			Auth.verifyAccount({
				verificationToken,
			})
		);

		const responseInfo = yield call(() => response.data);

		if (responseInfo.success) {
			yield call(resolve, responseInfo.message);
		} else {
			yield call(resolve, null);
		}
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage, errors] = errorHandler(error);
		yield call(reject, [errorMessage, errors]);
		yield put(fetchError());
	}
}

export function* authSaga() {
	yield takeLatest(TRY_AUTH_USER, tryAuthUser);
	yield takeLatest(TRY_AUTH_USERNAME, tryAuthUser);
	yield takeLatest(TRY_LOG_OUT, logOut);
	yield takeLatest(VERIFY_AUTH_TOKEN, verifyAuthUser);
	yield takeLatest(ON_REQUEST_RESET_PASSWORD, requestResetPassword);
	yield takeLatest(ON_RESET_PASSWORD, resetPassword);
	yield takeLatest(ON_VERIFY_ACCOUNT, verifyAccount);
}
