import React from "react";

import AppContext from "./AppContext";
import defaultContext from "./defaultContext";
import {THEME_TYPES} from "../../../constants/ThemeOptions";

const AppContextProvider = ({children}) => {
  // Use Redux to read user and tokens
  const themeType = THEME_TYPES.LIGHT;

  const contextValue = React.useMemo(
    () => {
      return {
        theme: {
          ...defaultContext.theme,
          palette: {
            ...defaultContext.theme.palette,
            // ...otherThemes[themeType].palette
          },
          overrides: {
            ...defaultContext.theme.overrides,
            // ...otherThemes[themeType].overrides
          },
          components: {
            ...defaultContext.theme.components,
          }
        },
        themeType,
      };
    },
    [themeType]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
