import { createReducer, removeFromObjByKeys } from 'utils';
import { RECEIVE_PAGE, REQUEST_PAGE } from 'redux/actions/pagination';
import {UPDATE_PAGINATION_PHOTOGRAPHY_SCORE} from "../../actions";

const initialState = {
	contests: {},
	users: {},
	photographies: {},
	contestsPhotographies: {},
	judgePhotographs: {},
	pagination: {
		contests: {
			currentPage: 1,
			totalOfItems: 0,
			limitOfPages: 0,
			pages: {}
		},
		users: {
			currentPage: 1,
			totalOfItems: 0,
			limitOfPages: 0,
			pages: {}
		},
		photographies: {
			currentPage: 1,
			totalOfItems: 0,
			limitOfPages: 0,
			pages: {}
		},
		contestsPhotographies: {
			currentPage: 1,
			totalOfItems: 0,
			limitOfPages: 0,
			pages: {}
		},
		judgePhotographs: {
			currentPage: 1,
			totalOfItems: 0,
			limitOfPages: 0,
			pages: {}
		},
	}
};

export default createReducer(initialState, {
	[REQUEST_PAGE](state, action) {
		let newItems = {};
		const currentItemsIdsToDelete = state.pagination[action.key].pages[
			action.page
		]
			? state.pagination[action.key].pages[action.page].ids
			: [];

		if (currentItemsIdsToDelete.length) {
			newItems = removeFromObjByKeys(
				state[action.key],
				currentItemsIdsToDelete
			);
		}

		return {
			...state,
			[action.key]: {
				...state[action.key],
				...newItems
			},
			pagination: {
				...state.pagination,
				[action.key]: {
					...state.pagination[action.key],
					currentPage: action.page,
					pages: {
						...state.pagination[action.key].pages,
						[action.page]: {
							ids: [],
							fetching: true
						}
					}
				}
			}
		};
	},
	[RECEIVE_PAGE](state, action) {
		let items = {};
		for (let item of action.items) {
			items[item.id] = { ...item }
		}

		return {
			...state,
			[action.key]: {
				...state[action.key],
				...items
			},
			pagination: {
				...state.pagination,
				[action.key]: {
					...state.pagination[action.key],
					currentPage: action.page,
					totalOfItems: action.total,
					limitOfPages: action.limit,
					pages: {
						...state.pagination[action.key].pages,
						[action.page]: {
							ids: [...action.items.map(item => item.id)],
							fetching: false
						}
					}
				}
			}
		};
	},
	[UPDATE_PAGINATION_PHOTOGRAPHY_SCORE](state, action) {
		return {
			...state,
			judgePhotographs: {
				...state.judgePhotographs,
				[action.contestJudgePhotographyId]: {
					...state.judgePhotographs[action.contestJudgePhotographyId],
					score: action.score
				}
			}
		}
	}
});
