import React, { useContext } from 'react';
import FooterLogo from './FooterLogo';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import { THEME_TYPES } from '../../../constants/ThemeOptions';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	btnRoot: {
		[theme.breakpoints.down('sm')]: {
			padding: '6px 12px',
			fontSize: 11
		}
	}
}));

const Footer = props => {
	const classes = useStyles();
	const { themeType } = useContext(AppContext);
	const date = new Date();

	return (
        <Box className={classes.root} {...props}>
			<Box display="flex" alignItems="center">
				<Hidden smDown>
					<FooterLogo
						mr={5}
						color={themeType === THEME_TYPES.DARK ? 'white' : ''}
					/>
				</Hidden>
				<Box
					fontSize={{ xs: 12, sm: 14 }}
					component="p"
					color="text.disabled"
				>
					Centinelas del Tiempo © {date.getFullYear()}
				</Box>
			</Box>
		</Box>
    );
};

export default Footer;
