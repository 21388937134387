/* eslint-disable no-useless-escape */
// noinspection RegExpRedundantEscape

import { isAfter, isBefore, isValid, sub } from 'date-fns';
import cleanPhoneNumber from 'utils/cleanPhoneNumber';

export const required = value => (value ? undefined : 'Campo requerido.');

export const requiredIf = (fieldNameToBeCompared, currentFormValues) => value => {
	if (Object.prototype.hasOwnProperty.call(currentFormValues, fieldNameToBeCompared)) {
		if (currentFormValues[fieldNameToBeCompared] === value) {
			return undefined;
		}
		return false;
	}
	return undefined;
};

export const mustBeNumber = value => {
	if (value) {
		return Number.isNaN(value) ? 'Debe ser un número' : undefined;
	}

	return undefined;
};

export const minLengthArr = min => value => {
	const text = min > 1 ? 'elementos' : 'elemento';
	return value && value.length >= min ? undefined : `Debe seleccionar ${min} ${text} o más.`;
};

export const minLength = min => value =>
	value && value.length >= min ? undefined : `Debe contener ${min} caracteres o más.`;

export const isLengthPhone = len => value => {
	if (value) {
		return cleanPhoneNumber(value).length === len ? undefined : `Debe contener ${len} dígitos.`;
	}
	return undefined;
};

export const maxLength = max => value =>
	value && value.length <= max ? undefined : `Debe contener ${max} caracteres o menos.`;

export const minValue = min => value =>
	Number.isNaN(value) || value >= min ? undefined : `Debe ser mayor que ${min}.`;

export const maxValue = max => value =>
	Number.isNaN(value) || value <= max ? undefined : `Debe ser menor que ${max}.`;

export const mustBeEvenNumber = value =>
	Number.isNaN(value) || value % 2 === 0 ? undefined : `Debe ingresar un número par.`;

export const isValidDate = value => {
	return isValid(new Date(value)) ? undefined : 'Debe ser una fecha valida.';
};

export const minYearsValid =
	(minYears, customMessage = false) =>
	value => {
		const message = customMessage ? customMessage : `Debe ser menor a ${minYears} años.`;
		return isAfter(new Date(value), sub(new Date(), { years: minYears })) ? undefined : message;
	};

export const maxYearsValid = maxYears => value =>
	isBefore(new Date(value), sub(new Date(), { years: maxYears }))
		? undefined
		: `Debe ser mayor a ${maxYears} años.`;

export const isEmail = value =>
	value &&
	// eslint-disable-next-line no-useless-escape
	/^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@((?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i.test(
		value
	)
		? undefined
		: 'Ingresa un correo electrónico válido.';

export const isValidPassword = value =>
	/* value &&
	// eslint-disable-next-line no-useless-escape
	/^.*(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\¡\£\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$/.test(
		value
	) */
	value && /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{0,})\S$/.test(value)
		? undefined
		: `- Debe contener al menos una letra minúscula.<br>
		- Debe contener al menos una letra mayúscula.<br>
		- Debe contener al menos un número.<br>
		- No debe contener espacios.`;

export const confirmation = (fieldNameToBeCompared, fieldLabel, currentFormValues) => value => {
	if (Object.prototype.hasOwnProperty.call(currentFormValues, fieldNameToBeCompared)) {
		if (currentFormValues[fieldNameToBeCompared] === value) {
			return undefined;
		}
		return `Debe coincidir con el campo ${fieldLabel}`;
	}
	return undefined;
};

export const isUrl = value => {
	let rUrl =
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

	return value && rUrl.test(value) ? undefined : 'Debe ser una URL válida.';
};

export const optional = () => {
	return undefined;
};

export const composeValidators = (...validators) => {
	return value => {
		if (validators[0].name === 'optional' && !value) {
			return undefined;
		}
		return validators.reduce((error, validator) => {
			return error || validator(value);
		}, undefined);
	};
};
