import { makeActionCreator } from 'utils';

export const SET_LAST_CONTEST = 'SET_LAST_CONTEST';
export const setLastContest = makeActionCreator(
	SET_LAST_CONTEST,
	'categories',
	'registrationStageOpenAt',
	'registrationStageClosedAt',
	'evaluationStageOpenAt',
	'evaluationStageClosedAt',
	'name',
	'id'
);

export const SET_CLEAN_LAST_CONTEST = 'SET_CLEAN_LAST_CONTEST';
export const setCleanLastContest = makeActionCreator(SET_CLEAN_LAST_CONTEST);

export const TRY_SET_LAST_CONTEST = 'TRY_SET_LAST_CONTEST';
export const trySetLastContest = makeActionCreator(
	TRY_SET_LAST_CONTEST,
	'categories',
	'registrationStageOpenAt',
	'registrationStageClosedAt',
	'evaluationStageOpenAt',
	'evaluationStageClosedAt',
	'name',
	'id'
);
