/* eslint-disable no-undef */
import { applyMiddleware, createStore } from 'redux';
import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import { loadState, saveState } from './localStorage';
import * as sagas from '../sagas';
import createRootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();
const persistedState = loadState();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
	history: createBrowserHistory(),
});

const bindMiddleware = middleware => {
	if (process.env.NODE_ENV !== 'production') {
		const { composeWithDevTools } = require('redux-devtools-extension');
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

function configureStore() {
	const store = createStore(
		createRootReducer(routerReducer),
		persistedState,
		bindMiddleware([routerMiddleware, sagaMiddleware])
	);

	store.subscribe(() => {
		const { auth, lastContest } = store.getState();
		saveState({
			auth,
			lastContest,
		});
	});

	const initSagas = sagaMiddleware => {
		Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
	};
	initSagas(sagaMiddleware);

	return {
		store,
		history: createReduxHistory(store),
	};
}

export default configureStore;
