import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import globalStyles from '../../../theme/GlobalCss';
import { useDispatch, useSelector } from 'react-redux';
import VerticalDefault from './VerticalLayouts/VerticalDefault';
import { setAccessToken, tryLogOut, trySetLastContest } from 'redux/actions';
import { getAccessToken, getRefreshToken } from 'redux/selectors';
import Contest from '../../../services/endpoints/Contest';
import Auth from 'services/endpoints/Auth';
import styled from 'styled-components';
import { jwtDecode } from 'jwt-decode';
import { isAfter, subMilliseconds } from 'date-fns';

const LoadingContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const AppLayout = ({ children }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const milisecondsToRefreshToken = 900000;

	const [showLayoutLoader, setLayoutLoader] = useState(true);

	const { loadUser } = useSelector(({ auth }) => auth);

	const accessToken = useSelector(getAccessToken);
	const refreshToken = useSelector(getRefreshToken);
	globalStyles();

	const refreshTokenAction = useCallback(() => {
		return Auth.refreshToken({
			refreshToken,
		})
			.then(response => {
				dispatch(setAccessToken(response.data?.accessToken, response.data?.refreshToken));
			})
			.catch(() => {
				navigate('/iniciar-sesion');
				dispatch(tryLogOut());
			});
	}, [dispatch, navigate, refreshToken]);

	// This checks if refresh Token is already expired and log out the user
	useEffect(() => {
		if (accessToken && refreshToken) {
			const { exp: expRefreshToken } = jwtDecode(refreshToken);
			const expRefreshTokenDate = new Date(expRefreshToken * 1000);
			if (isAfter(new Date(), expRefreshTokenDate)) {
				// Refresh Token already expired, let's logout
				dispatch(tryLogOut());
				setLayoutLoader(false);
				navigate('/iniciar-sesion');
			} else {
				const { exp: expAccessToken } = jwtDecode(accessToken);
				const expAccessTokenDate = subMilliseconds(
					new Date(expAccessToken * 1000),
					+milisecondsToRefreshToken + 2000
				);

				// Today is after the expiration token day
				if (isAfter(new Date(), expAccessTokenDate)) {
					refreshTokenAction().then(() => setLayoutLoader(false));
				} else {
					setLayoutLoader(false);
				}
			}
		} else {
			dispatch(tryLogOut());
			setLayoutLoader(false);
		}
	}, [dispatch, accessToken, refreshToken, navigate, refreshTokenAction]);

	// This set an interval to refresh access token every X time
	useEffect(() => {
		let interval = null;
		if (refreshToken) {
			interval = setInterval(() => {
				refreshTokenAction().then(() => {});
			}, milisecondsToRefreshToken);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [dispatch, refreshToken, navigate, refreshTokenAction]);

	const loadContest = useCallback(() => {
		// TODO: Create a proper catch method
		return Contest.lastContest()
			.then(response => {
				const {
					categories,
					registrationStageOpenAt,
					registrationStageClosedAt,
					evaluationStageOpenAt,
					evaluationStageClosedAt,
					name,
					id,
				} = response.data.lastContest;
				dispatch(
					trySetLastContest(
						categories,
						registrationStageOpenAt,
						registrationStageClosedAt,
						evaluationStageOpenAt,
						evaluationStageClosedAt,
						name,
						id
					)
				);
			})
			.catch(() => {
				refreshTokenAction().then(() => {});
			});
	}, [dispatch, refreshTokenAction]);

	useEffect(() => {
		loadContest();
		const interval = setInterval(() => {
			loadContest();
		}, 600000);

		return () => {
			clearInterval(interval);
		};
	}, [loadContest]);

	if (showLayoutLoader || !loadUser) {
		return (
			<LoadingContainer>
				<CircularProgress />
			</LoadingContainer>
		);
	}

	if (
		[
			'/iniciar-sesion',
			'/iniciar-sesion-como-juez',
			'/registrarse',
			'/recuperar-contrasena',
			'/restablecer-contrasena',
			'/verificar-cuenta',
			'/confirmar-cambio-email',
			'/confirmar-nuevo-email',
		].includes(location.pathname)
	) {
		return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
	}

	return <VerticalDefault>{children}</VerticalDefault>;
};

export default AppLayout;
