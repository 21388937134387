import React, { useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { alpha, Box, IconButton, Typography } from '@mui/material';
import CmtImage from '@coremat/CmtImage';
import { makeStyles } from '@mui/styles';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel/CircularProgressWithLabel';

const baseStyle = {
	cursor: 'pointer',
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#4caf50',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: 'rgba(0, 0, 0, 0.6)',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};
const useStyles = makeStyles(() => ({
	container: {
		marginBottom: 16,
	},
	dropZoneContainer: {
		'&[disabled]': {
			cursor: 'not-allowed !important',
			borderColor: 'rgba(0, 0, 0, 0.38) !important',
		},
	},
	successText: {
		color: 'rgb(76, 175, 80)',
		fontSize: 16,
	},
	dropZoneContainerError: {
		borderColor: 'red !important',
	},
	errorText: {
		fontSize: 16,
	},
	thumbsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	image: {
		height: 32,
		width: 45,
		marginBottom: 16,
	},

	thumb: {
		display: 'inline-flex',
		width: 'auto',
		height: 'auto',
		padding: 4,
		boxSizing: 'border-box',
		position: 'relative',
		marginRight: 16,
	},
	thumbInner: {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: 4,
	},
	icon: {
		backgroundColor: alpha('#ff1744', 0.5),
		position: 'absolute',
		top: 0,
		right: 0,
		'&:hover': {
			backgroundColor: alpha('#ff1744', 0.5),
		},
	},
	img: {
		display: 'block',
		width: 'auto',
		height: '100%',
		maxHeight: '350px',
	},
}));

const CustomDropzone = ({
	onChange,
	submit,
	error,
	errorText,
	progressLabel,
	progressLoaded,
	files = [],
	setFiles,
	removeFile,
}) => {
	const classes = useStyles();
	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		accept: 'image/*',
		multiple: false,
		onDrop: acceptedFiles => {
			const uploadedFiles = acceptedFiles.map(file =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			);
			if (onChange) {
				onChange(uploadedFiles);
				submit();
			}
		},
	});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);

	const deleteFile = file => () => {
		if (onChange) {
			removeFile(file.id)
				.then(() => {
					onChange([]);
					setFiles([]);
					submit();
				})
				.catch(error => console.log(error));
		}
	};

	const thumbs = files.map(file => {
		let fileSrc = file.image[0].preview;
		if (file.image[0].type === 'image/tiff') {
			fileSrc = `${file.filePath}${file.filename}`;
		}
		return (
			<div className={classes.thumb} key={file.id}>
				<div className={classes.thumbInner}>
					<img src={fileSrc} className={classes.img} alt="" />
				</div>
				<IconButton
					className={classes.icon}
					onClick={deleteFile(file)}
					size="small"
					color="default"
				>
					<CloseIcon />
				</IconButton>
			</div>
		);
	});

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach(file => URL.revokeObjectURL(file.image[0].path));
		},
		[files]
	);

	const disabled = files.length;

	return (
		<Box className={classes.container}>
			{!disabled && (
				<Box
					{...getRootProps({ style })}
					disabled={disabled}
					className={clsx({
						[classes.dropZoneContainer]: true,
						[classes.dropZoneContainerError]: error,
					})}
				>
					<input {...getInputProps()} disabled={disabled} />

					{!progressLoaded && (
						<CmtImage src="/images/upload.svg" className={classes.image} />
					)}
					{error && !progressLoaded && (
						<FormHelperText className={classes.errorText} error={error}>
							{errorText}
						</FormHelperText>
					)}
					{!error && !progressLoaded && (
						<Typography>
							Arrastra tu fotografía aquí, o haz clic para seleccionarla.
						</Typography>
					)}

					{progressLoaded > 0 && (
						<CircularProgressWithLabel label={progressLabel} value={progressLoaded} />
					)}
				</Box>
			)}
			<aside className={classes.thumbsContainer}>
				{thumbs}
				{!error && thumbs.length !== 0 && !progressLoaded && (
					<FormHelperText className={classes.successText}>
						{`Hemos recibido tu fotografía, por favor completa los campos y haz clic en el
						botón de 'Registrar' para terminar con el registro.`}
					</FormHelperText>
				)}
			</aside>
		</Box>
	);
};

export default CustomDropzone;
