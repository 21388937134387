import React from 'react';
import Hidden from '@mui/material/Hidden';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
	display: flex;
	align-items: center;
`;
const LogoCentinelas = styled(CmtImage)`
	max-height: 70px;
	margin-right: 20px;
`;

const LogoReforestamos = styled(CmtImage)`
	max-height: 55px;
	margin-right: 20px;
`;

const StyledBox = styled(Box)`
	padding: 10px 0;
`;

const Logo = ({ ...props }) => {
	return (
		<StyledBox className="pointer" {...props}>
			<Hidden smDown>
				<StyledNavLink to="/">
					<LogoCentinelas
						src={'/images/logo-centinelas-blanco.png'}
						alt="Logo Centinelas del Tiempo"
					/>
					<LogoReforestamos
						src={'/images/reforestamosmx_blanco.png'}
						alt="Logo Reforestamos México"
					/>
				</StyledNavLink>
			</Hidden>
			<Hidden smUp>
				<StyledNavLink to="/">
					<LogoCentinelas
						src={'/images/logo-centinelas-blanco.png'}
						alt="Logo Centinelas del Tiempo"
					/>
					<LogoReforestamos
						src={'/images/reforestamosmx_blanco.png'}
						alt="Logo Reforestamos México"
					/>
				</StyledNavLink>
			</Hidden>
		</StyledBox>
	);
};

export default Logo;
