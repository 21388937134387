import React from 'react';
import {Dashboard, Group, Note, RateReview, SupervisorAccount} from '@mui/icons-material';
import Equalizer from "@mui/icons-material/Equalizer";

const menu = [
	{
		name: 'Principal',
		type: 'section',
		children: [
			{
				name: 'General',
				type: 'item',
				icon: <Dashboard />,
				link: '/panel'
			}
		]
	}
];

export const judgeMenu = [
	{
		name: 'Principal',
		type: 'section',
		children: [
			{
				name: 'Evaluación',
				type: 'item',
				icon: <RateReview/>,
				link: '/evaluacion'
			}
		],
	}
];

export const adminMenu = [
	{
		name: 'Principal',
		type: 'section',
		children: [
			{
				name: 'Estadisticas',
				type: 'item',
				icon: <Equalizer />,
				link: '/estadisticas'
			}
			,{
				name: 'General',
				type: 'item',
				icon: <Dashboard />,
				link: '/panel'
			},
			{
				name: 'Concursos',
				type: 'item',
				icon: <Note />,
				link: '/concursos'
			},
			{
				name: 'Usuarios',
				type: 'item',
				icon: <Group />,
				link: '/usuarios'
			},
			{
				name: 'Administradores',
				type: 'item',
				icon: <SupervisorAccount />,
				link: '/administradores'
			}
		]
	}
];

export const sidebarNavs = menu;

export const horizontalDefaultNavs = menu;

export const minimalHorizontalMenus = menu;
