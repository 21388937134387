import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@mui/styles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import {sidebarNavs, adminMenu, judgeMenu} from '../menus';
import SideBarFilters from "../../../../../components/SideBarFilters/SideBarFilters";

const useStyles = makeStyles(() => ({
	perfectScrollbarSidebar: {
		height: '100%',
		transition: 'all 0.3s ease',
		'.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
			height: 'calc(100% - 167px)'
		},
		'.Cmt-modernLayout &': {
			height: 'calc(100% - 72px)'
		},
		'.Cmt-miniLayout &': {
			height: 'calc(100% - 91px)'
		},
		'.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
			height: 'calc(100% - 167px)'
		}
	}
}));

const SideBar = ({ authUser }) => {
	const classes = useStyles();

	let menu;

	switch (authUser.roleId) {
		case 1:
			menu = adminMenu;
			break;
		case 3:
			menu = judgeMenu;
			break;
		default:
			menu = sidebarNavs;
	}

	return (
		<PerfectScrollbar className={classes.perfectScrollbarSidebar}>
			<CmtVertical menuItems={menu} />
			{authUser.roleId === 3 && <SideBarFilters />}
		</PerfectScrollbar>
	);
};

export default SideBar;
