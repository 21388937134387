import { call, put, select, takeLatest } from 'redux-saga/effects';

import { errorHandler } from 'utils/errorHandler';
import { getAccessToken } from 'redux/selectors';

import Judge from "../../../services/endpoints/Judge";
import {
    fetchError,
    initialEvaluationSet, setFilteredBy,
    TRY_INITIAL_EVALUATION_SET, TRY_SET_FILTERED_BY,
    TRY_UPDATE_PHOTOGRAPHY_SCORE, updateEvaluationScoreKey,
    updatePaginationPhotographyScore
} from "../../actions";

function* tryInitialEvaluationSet() {
    const accessToken = yield select(getAccessToken);
    if (!accessToken) {
        return false;
    }

    const headerParams = {
        Authorization: `Bearer ${accessToken}`
    };

    try {
        const response = yield call(() =>
            Judge.getStatistics({headers: headerParams})
        );

        const responseInfo = yield call(() => response.data);

        yield put(
            initialEvaluationSet(responseInfo.totalEvaluationItems, responseInfo.scoreItemKeys)
        );
    } catch (error) {
        const [errorMessage] = errorHandler(error);
        yield put(fetchError(errorMessage));
    }
}

function* tryUpdatePhotographyScore({contestJudgePhotographyId, photographyId, previousScore, newScore}) {
    const accessToken = yield select(getAccessToken);
    if (!accessToken) {
        return false;
    }

    const headerParams = {
        Authorization: `Bearer ${accessToken}`
    };

    try {
        const response = yield call(() =>
            Judge.updateScore(contestJudgePhotographyId, newScore, {headers: headerParams})
        );

        yield call(() => response.data);

        const prevScore = previousScore === null ? 'pending' : previousScore;
        yield put(updateEvaluationScoreKey(photographyId, prevScore, newScore));
        yield put(updatePaginationPhotographyScore(contestJudgePhotographyId, newScore));

    } catch (error) {
        const [errorMessage] = errorHandler(error);
        yield put(fetchError(errorMessage));
    }
}

function* trySetFilteredBy({filteredBy}) {
    yield put(setFilteredBy(filteredBy));
}

export function* judgeSaga() {
    yield takeLatest(TRY_INITIAL_EVALUATION_SET, tryInitialEvaluationSet);
    yield takeLatest(TRY_UPDATE_PHOTOGRAPHY_SCORE, tryUpdatePhotographyScore);
    yield takeLatest(TRY_SET_FILTERED_BY, trySetFilteredBy);
}