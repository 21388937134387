import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PageLoader from '../PageComponents/PageLoader';
import { useSnackbar } from 'notistack';
import { fetchClean } from 'redux/actions';
const ContentLoader = () => {
	const { loading, message, messageType } = useSelector(
		({ common }) => common
	);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	useEffect(() => {
		if (message && message.length) {
			enqueueSnackbar(message, {
				variant: messageType,
				autoHideDuration: 8000
			});
			dispatch(fetchClean());
		}
	});

	return <PageLoader open={loading} />;
};

export default ContentLoader;
