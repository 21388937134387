export const assignColorPaletteCategory = categories => {
	const colors = ['#4CAF50', '#8BC34A', '#CDDC39', '#3bbaff', '#075eff', '#ffffff'];

	const sortedCategories = categories.sort((a, b) => {
		if (a.shortName < b.shortName) {
			return -1;
		} else if (a.shortName > b.shortName) {
			return 1;
		}

		return 0;
	});

	return sortedCategories.map((category, index) => ({
		...category,
		color: colors[index],
	}));
};
