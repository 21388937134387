import React from 'react';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@mui/material/Toolbar';
import {Box, Button} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '../Logo';
import {tryLogOut} from "../../../../../redux/actions";
import {useDispatch} from "react-redux";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		paddingLeft: 16,
		paddingRight: 16,
		minHeight: 64,
		[theme.breakpoints.up('md')]: {
			minHeight: 72
		},
		[theme.breakpoints.up('md')]: {
			paddingLeft: 24,
			paddingRight: 24
		}
	},
	exitIcon: {
		marginRight: 10,
	},
	closeSessionButton: {
		[theme.breakpoints.down('md')]: {
			marginLeft: 5,
			fontSize: 10,
			lineHeight: '1.2',
		},
	}
}));

const Header = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const onLogoutHandler = () => {
		dispatch(tryLogOut());
	};

	return (
		<Toolbar className={classes.root}>
			<SidebarToggleHandler
				edge="start"
				color="inherit"
				aria-label="menu"
			/>
			<Logo ml={2} color="white" />
			<Box flex={1} />
			<Button
				className={classes.closeSessionButton}
				onClick={onLogoutHandler}
				variant="contained"
				size="small"
				color="primary"
				startIcon={<ExitToAppIcon />}>
				Cerrar Sesión
			</Button>
		</Toolbar>
	);
};

export default Header;
