import defaultTheme from "../../../../theme/defaultTheme";
import {
  DRAWER_BREAK_POINT,
  HEADER_TYPE,
  LAYOUT_STYLES,
  LAYOUT_TYPES,
  SIDEBAR_TYPE,
  SIDEBAR_WIDTH,
  THEME_TYPES
} from "../../../constants/ThemeOptions";

const theme = {
  theme: defaultTheme,
  defaultLng: {
    languageId: "spanish",
    locale: "es",
    name: "Español",
    icon: "mx"
  },
  layout: LAYOUT_TYPES.VERTICAL_DEFAULT,
  layoutType: LAYOUT_STYLES.FRAMED,
  themeType: THEME_TYPES.LIGHT,
  drawerBreakPoint: DRAWER_BREAK_POINT.MD,
  headerType: HEADER_TYPE.FIXED,
  sidebarType: SIDEBAR_TYPE.FULL,
  isSidebarFixed: true,
  sidebarWidth: SIDEBAR_WIDTH.SMALL,
  showFooter: false,
}
export default theme;
