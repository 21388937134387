import { createReducer } from 'utils';

import {
	SET_DELETE_USER_PHOTOGRAPHY,
	SET_USER_PHOTOGRAPHIES
} from '../../actions';

const initialState = {
	items: null,
	itemsPerCategory: {
		total: null,
		categories: {},
	}
};

export default createReducer(initialState, {
	[SET_USER_PHOTOGRAPHIES](state, action) {
		return {
			...state,
			items: action.items,
			itemsPerCategory: {
				...state.itemsPerCategory,
				total: action.total,
				categories: action.categories
			}
		};
	},
	[SET_DELETE_USER_PHOTOGRAPHY](state, action) {
		return {
			...state,
			items: [
				...state.items.filter(item => item.id !== action.id)
			],
			itemsPerCategory: {
				...state.itemsPerCategory,
				total: state.itemsPerCategory.total - 1,
				[action.categorySlug]:
					state.itemsPerCategory.categories[action.categorySlug] - 1
			}
		};
	}
});
