import { destroy, get, put, post } from 'services/api';

const Contest = {
	getAll: (config) => get('/contests/?sort=-created_at', config),
	lastContest: () =>  get('/contests/last-contest'),
	create: (data, config) => post(`/contests`, data, config),
	delete: (contestId, config) => destroy(`/contests/${contestId}`, config),
	read: (contestId, config) => get(`/contests/${contestId}`, config),
	search: (sort, page, pageSize, search, params, config) =>
		get(
			`/contests/?sort=${sort}&pageSize=${pageSize}&page=${page}&searchKey=${search}`,
			config
		),
	searchPhotographies: (sort, page, pageSize, search, params, config) => {
		const {contestId} = params;
		return get(
			`/contests/${contestId}/photographies/?sort=${sort}&pageSize=${pageSize}&page=${page}&searchKey=${search}`,
			config
		)
	},
	update: (contestId, data, config) =>
		put(`/contests/${contestId}`, data, config),
	createJudges: (contestId, data, config) =>
		post(`/contests/${contestId}/judges`, data, config),
	getJudges: (contestId, config) =>
		get(`/contests/${contestId}/judges`, config),
	deleteJudges: (contestId, config) =>
		destroy(`/contests/${contestId}/judges`, config),
	getStatistics: (contestId, config) => get(`/contests/${contestId}/statistics`, config),
	generateAllPhotosZip: (contestId, config) => post(`/contests/${contestId}/createZipForAllPhotos`, {}, config),
	generateBestPhotosZip: (contestId, config) => post(`/contests/${contestId}/createZipForBestPhotos`, {}, config),
};

export default Contest;
