import {createReducer} from "utils";

import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  FETCH_CLEAN,
  FETCH_INFO
} from "../../actions";

const initialState = {
  messageType: "", // 'success', 'warning', 'info', 'error'
  message: "",
  loading: false
};

export default createReducer(initialState, {
  [FETCH_START](state) {
    return {
      ...state,
      messageType: "",
      message: "",
      loading: true
    };
  },
  [FETCH_SUCCESS](state, action) {
    return {
      ...state,
      messageType: "success",
      message: action.message,
      loading: false
    };
  },
  [FETCH_ERROR](state, action) {
    return {
      ...state,
      messageType: "error",
      message: action.message,
      loading: false
    };
  },
  [FETCH_INFO](state, action) {
    return {
      ...state,
      messageType: "info",
      message: action.message,
      loading: false
    };
  },
  [FETCH_CLEAN](state) {
    return {
      ...state,
      messageType: "",
      message: "",
      loading: false
    };
  }
});
