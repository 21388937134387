import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
	fetchError,
	fetchStart,
	fetchSuccess,
	setCleanUserProfile,
	setUserProfile,
	TRY_LOAD_USER_PROFILE,
	TRY_REGISTER_USER,
	TRY_UPDATE_USER_PROFILE
} from 'redux/actions';

import { errorHandler } from 'utils/errorHandler';
import { getAccessToken, getAuthUser } from 'redux/selectors';
import assetService from 'services/assetService';
import User from 'services/endpoints/User';

function* registerUser({ userData, resolve, reject }) {
	yield put(fetchStart());

	try {
		const response = yield call(() => User.create(userData));
		const responseInfo = yield call(() => response.data);

		if (responseInfo.success) {
			yield call(resolve, responseInfo.message);
		} else {
			yield call(resolve, null);
		}
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage, errors] = errorHandler(error);
		yield call(reject, errors);
		yield put(fetchError(errorMessage));
	}
}

function* loadUserProfile({ userId }) {
	const authUser = yield select(getAuthUser);
	yield put(fetchStart());
	const accessToken = yield select(getAccessToken);
	if (!accessToken) {
		yield put(fetchSuccess());
		return false;
	}
	const headerParams = {
		Authorization: `Bearer ${accessToken}`
	};

	try {
		const response = yield call(() =>
			User.read(userId, {
				headers: headerParams
			})
		);

		const responseInfo = yield call(() => response.data);

		yield put(
			setUserProfile(
				responseInfo.firstName,
				responseInfo.lastName,
				responseInfo.phone,
				responseInfo.mobilePhone,
				responseInfo.externalNumber,
				responseInfo.internalNumber,
				responseInfo.postalCode,
				responseInfo.colonia,
				responseInfo.street,
				responseInfo.birthDate,
				responseInfo.email,
				responseInfo.username,
				responseInfo.gender,
				responseInfo.instagram,
				responseInfo.twitter,
				responseInfo.facebook,
				responseInfo.lastLogin,
				authUser.roleId === 1 ? responseInfo.roleId : null,
				responseInfo.municipalityId,
				responseInfo.stateId,
				responseInfo.municipality,
				responseInfo.imTutor,
				responseInfo.minorName,
				responseInfo.verified,
				responseInfo.verifiedAt,
				responseInfo.createdAt,
				responseInfo.updatedAt
			)
		);
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage] = errorHandler(error);
		yield put(fetchError(errorMessage));
	}
}

function* updateUserProfile({ userId, userData, resolve, reject }) {
	yield put(fetchStart());
	const accessToken = yield select(getAccessToken);
	if (!accessToken) {
		yield put(fetchSuccess());
		return false;
	}
	const headerParams = {
		Authorization: `Bearer ${accessToken}`
	};

	try {
		const response = yield call(() =>
			User.update(userId, { ...userData }, { headers: headerParams })
		);

		const responseInfo = yield call(() => response.data);

		yield put(setCleanUserProfile());

		if (responseInfo.success) {
			yield call(resolve, responseInfo.message);
		} else {
			yield call(resolve, null);
		}

		if (Object.prototype.hasOwnProperty.call(userData, 'oldPassword')) {
			yield put(fetchSuccess(assetService.sagas.success.passwordChanged));
		} else if (Object.prototype.hasOwnProperty.call(userData, 'email')) {
			yield put(fetchSuccess(assetService.sagas.success.emailChanged));
		} else {
			yield put(fetchSuccess());
		}
	} catch (error) {
		const [errorMessage, errors] = errorHandler(error);
		yield call(reject, errors);
		yield put(fetchError(errorMessage));
	}
}

export function* userSaga() {
	yield takeLatest(TRY_REGISTER_USER, registerUser);
	yield takeLatest(TRY_LOAD_USER_PROFILE, loadUserProfile);
	yield takeLatest(TRY_UPDATE_USER_PROFILE, updateUserProfile);
}
