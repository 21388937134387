import React, {useState} from 'react';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Map = ({
	onChange,
	containerStyle,
	defaultPosition,
	error,
	editable,
	...props
}) => {
	const [markerPosition, setMarkerPosition] = useState(defaultPosition);

	const onMapClick = e => {
		if (!editable) return;
		if (onChange) {
			const latLng = `${e.latLng.lat()}, ${e.latLng.lng()}`;
			onChange(latLng);
		}
		setMarkerPosition(e.latLng);
	};

	return (
		<LoadScript googleMapsApiKey="AIzaSyDhchswZfzU3onWhGEJ1fjHsWjDHOFhCUM">
			<GoogleMap
				mapContainerStyle={{
					...containerStyle,
					borderColor: error ? '#E00930' : 'rgba(2, 2, 2, 0.12)'
				}}
				onClick={onMapClick}
				{...props}
			>
				<>{markerPosition && <Marker position={markerPosition} />}</>
			</GoogleMap>
		</LoadScript>
	);
};

export default Map;
