import { put, takeLatest } from 'redux-saga/effects';

import { setLastContest, TRY_SET_LAST_CONTEST } from 'redux/actions';
import { assignColorPaletteCategory } from 'utils/assignColorPaletteCategory';

function* trySetLastContestSaga({
	categories,
	registrationStageOpenAt,
	registrationStageClosedAt,
	evaluationStageOpenAt,
	evaluationStageClosedAt,
	name,
	id,
}) {
	const categoriesWithColors = assignColorPaletteCategory(categories);
	yield put(
		setLastContest(
			categoriesWithColors,
			registrationStageOpenAt,
			registrationStageClosedAt,
			evaluationStageOpenAt,
			evaluationStageClosedAt,
			name,
			id
		)
	);
}

export function* contestSaga() {
	yield takeLatest(TRY_SET_LAST_CONTEST, trySetLastContestSaga);
}
