import React, { useState } from 'react';
import clsx from 'clsx';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { isValid } from 'date-fns';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import Map from 'components/Map/Map';

const createMarkup = htmlText => ({
	__html: htmlText,
});

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: '25ch',
	},
	menuPaper: {
		maxHeight: 250,
	},
}));

const CustomField = ({
	input,
	type,
	select,
	meta,
	children,
	specialCharactersInHelperText,
	pickerVariant,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const classes = useStyles();
	const errorBool =
		meta.touched &&
		(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
		!meta.submitting;
	const errorText = errorBool ? (
		specialCharactersInHelperText ? (
			<span dangerouslySetInnerHTML={createMarkup(meta.submitError || meta.error)} />
		) : (
			meta.submitError || meta.error
		)
	) : null;
	if (select) {
		return (
			<TextField
				{...props}
				select
				type={type}
				error={errorBool}
				helperText={errorText}
				inputProps={{
					...input,
				}}
				SelectProps={{
					MenuProps: { classes: { paper: classes.menuPaper } },
				}}
			>
				{children}
			</TextField>
		);
	}

	if (type === 'date' || type === 'datetime') {
		const Component = type === 'date' ? DatePicker : DateTimePicker;

		return (
			<LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
				<Component
					{...props}
					{...input}
					value={
						input.value && isValid(new Date(input.value)) ? new Date(input.value) : null
					}
					onChange={date => {
						return isValid(date)
							? input.onChange(new Date(date).toISOString())
							: input.onChange(date);
					}}
					inputVariant={props.variant}
					variant={pickerVariant}
					error={errorBool}
					KeyboardButtonProps={{
						'aria-label': 'Cambiar fecha',
					}}
					slotProps={{
						textField: {
							fullWidth: true,
							margin: 'normal',
							color: 'primary',
							error: errorBool,
							helperText: errorText,
							onBlur: input.onBlur,
						},
					}}
				/>
			</LocalizationProvider>
		);
	}

	if (type === 'password') {
		return (
			<FormControl
				className={clsx(classes.margin, classes.textField)}
				{...props}
				error={errorBool}
			>
				<InputLabel htmlFor={input.name}>{props.label}</InputLabel>
				<OutlinedInput
					id={input.name}
					type={showPassword ? 'text' : 'password'}
					{...input}
					label={props.label}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(!showPassword)}
								onMouseDown={e => e.preventDefault()}
								edge="end"
								size="large"
							>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
				/>
				{errorBool && <FormHelperText>{errorText}</FormHelperText>}
			</FormControl>
		);
	}

	if (type === 'checkbox') {
		const { classes, ...innerProps } = props;
		return (
			<>
				<FormControlLabel
					{...innerProps}
					{...input}
					control={<Checkbox name={input.name} color="primary" />}
				/>
				{errorBool && (
					<FormHelperText error classes={classes}>
						{errorText}
					</FormHelperText>
				)}
			</>
		);
	}

	if (type === 'file') {
		return <CustomDropzone {...props} {...input} error={errorBool} errorText={errorText} />;
	}

	if (type === 'map') {
		return (
			<>
				<Map {...props} {...input} error={errorBool} />
				{errorBool && <FormHelperText error>{errorText}</FormHelperText>}
			</>
		);
	}

	return <TextField {...props} {...input} type={type} error={errorBool} helperText={errorText} />;
};

export default CustomField;
