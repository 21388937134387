import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';

import {
	MenuItem,
	MenuList,
	Paper,
	Popover,
	Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';

import { makeStyles } from '@mui/styles';
import ChangePasswordDialog from 'components/ChangePasswordDialog/ChangePasswordDialog';
import ChangeEmailDialog from 'components/ChangeEmailDialog/ChangeEmailDialog';

const useStyles = makeStyles(theme => ({
	root: {
		padding: '30px 16px 12px 16px',
		borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`
	},
	userInfo: {
		paddingTop: 24,
		transition: 'all 0.1s ease',
		height: 'auto',
		opacity: 1,
		'.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
			height: 0,
			paddingTop: 0,
			opacity: 0,
			transition: 'all 0.3s ease'
		}
	},
	userTitle: {
		color: props => props.sidebarTheme.textDarkColor,
		marginBottom: 8
	},
	userSubTitle: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		letterSpacing: 0.25
	}
}));

const SidebarHeader = ({ authUser }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [openChangePassword, setOpenChangePassword] = useState(false);
	const [openChangeEmail, setOpenChangeEmail] = useState(false);

	let navigate = useNavigate();

	const { sidebarTheme } = useContext(SidebarThemeContext);
	const classes = useStyles({ sidebarTheme });

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleOpenProfile = () => {
		handlePopoverClose();
		navigate(`/user/${authUser.userId}`);
	};

	const handleOpenChangePassword = () => {
		handlePopoverClose();
		setOpenChangePassword(true);
	};

	const handleOpenChangeEmail = () => {
		handlePopoverClose();
		setOpenChangeEmail(true);
	};

	const handleCloseChangePassword = () => {
		setOpenChangePassword(false);
	};

	const handleCloseChangeEmail = () => {
		setOpenChangeEmail(false);
	};

	const open = Boolean(anchorEl);

	return (
		<div className={classes.root}>
			<div className={classes.userInfo} onClick={handlePopoverOpen}>
				<div
					className="pointer"
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-end'
					}}
				>
					<div className="mr-2">
						<Typography
							className={classes.userTitle}
							component="h3"
							variant="h6"
						>
							{authUser.displayName}
						</Typography>
						<Typography className={classes.userSubTitle}>
							{authUser.email ? authUser.email : authUser.username}
						</Typography>
					</div>
					{authUser.roleId !== 3 && <ArrowDropDownIcon />}
				</div>
			</div>

			{open && authUser.roleId !== 3 && (
				<Popover
					open={open}
					anchorEl={anchorEl}
					container={anchorEl}
					onClose={handlePopoverClose}
					anchorOrigin={{
						vertical: 'center',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
				>
					<Paper elevation={8}>
						<MenuList>
							<MenuItem onClick={handleOpenProfile}>
								<PersonIcon />
								<div className="ml-2">Perfil</div>
							</MenuItem>
							<MenuItem onClick={handleOpenChangePassword}>
								<LockIcon />
								<div className="ml-2">Cambiar Contraseña</div>
							</MenuItem>
							<MenuItem onClick={handleOpenChangeEmail}>
								<EmailIcon />
								<div className="ml-2">Cambiar Email</div>
							</MenuItem>
							{/*<MenuItem onClick={onLogoutClick}>
								<ExitToAppIcon />
								<div className="ml-2">Cerrar Sesión</div>
							</MenuItem>*/}
						</MenuList>
					</Paper>
				</Popover>
			)}

			<ChangePasswordDialog
				open={openChangePassword}
				handleClose={handleCloseChangePassword}
			/>
			<ChangeEmailDialog
				open={openChangeEmail}
				handleClose={handleCloseChangeEmail}
			/>
		</div>
	);
};

export default SidebarHeader;
