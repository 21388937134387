/* eslint-disable no-undef */
const assetService = {
	sagas: {
		error: {
			connectionRefused: 'El servidor no responde, favor de interntar más tarde.',
		},
		success: {
			welcomeMessage: 'Has iniciado sesión correctamente, bienvenido.',
			passwordChanged: 'Se ha cambiado tu contraseña satisfactoriamente.',
			emailChanged:
				'Hemos enviado un correo electrónico a tu email actual para verificar el cambio.',
		},
	},
	axios: {
		tokenExpired: 'Su sesión ha caducado, inicie sesión.',
		status: {
			500: 'Error interno del servidor, favor de reportarlo.',
		},
	},
	photographies: {
		path:
			process.env.NODE_ENV === 'production'
				? 'https://cdn.centinelasdeltiempo.org/photographies'
				: 'https://cdn.rgluis.com/photographies',
	},
	zip: {
		path:
			process.env.NODE_ENV === 'production'
				? 'https://cdn.centinelasdeltiempo.org/zip'
				: 'https://cdn.rgluis.com/zip',
	},
};

export default assetService;
