import React, {useContext} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {ListItem, ListItemSecondaryAction} from "@mui/material";
import styled from "styled-components";
import {alpha} from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import SidebarThemeContext from "../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";

const StyledListItem = styled(ListItem)`
    border-radius: 40px;
    width: 95%;
    margin: 0 auto 10px;
    cursor: pointer;
`;

const StyledCounter = styled.div`
    background-color: ${alpha('#4caf50', 1)};
    padding: 0 9px;
    height: 25px;
    font-size: 0.9rem;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`;

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const useStyles = makeStyles(() => ({
    listIcon: {
        minWidth: "30px",
    },
}));

const CustomListItem = ({active, onClick, label, quantity, icon, children}) => {
    const { sidebarTheme } = useContext(SidebarThemeContext);
    const classes = useStyles({ sidebarTheme });

    return <StyledListItem
        style={{
            backgroundColor: active ? alpha('#4caf50', 0.2) : alpha('#e0e0e0', 0.3)
        }}
        onClick={onClick}
    >
        <ListItemIcon className={classes.listIcon}>
            {icon}
        </ListItemIcon>
        <StyledContentContainer>
            <ListItemText
                primary={label}
                primaryTypographyProps={{
                    style: {
                        fontSize: "13px"
                    }
                }}
            />
            {children}
        </StyledContentContainer>

        {quantity !== "-" && <ListItemSecondaryAction>
            <StyledCounter>
                {quantity}
            </StyledCounter>
        </ListItemSecondaryAction>}
    </StyledListItem>
};

export default CustomListItem;