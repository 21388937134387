import {makeActionCreator} from "utils";

export const FETCH_START = "FETCH_START";
export const fetchStart = makeActionCreator(FETCH_START);

export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const fetchSuccess = makeActionCreator(FETCH_SUCCESS, "message");

export const FETCH_ERROR = "FETCH_ERROR";
export const fetchError = makeActionCreator(FETCH_ERROR, "message");

export const FETCH_INFO = "FETCH_INFO";
export const fetchInfo = makeActionCreator(FETCH_INFO, "message");

export const FETCH_CLEAN = "FETCH_CLEAN";
export const fetchClean = makeActionCreator(FETCH_CLEAN);
