import {post, destroy, put, get} from '../api';

const Photography = {
	read: (photographyId, config) => get(`/photographies/${photographyId}`, config),
	update: (photographyId, data, config) => put(`/photographies/${photographyId}`, data, config),
	create: (data, config) => post('/photographies', data, config),
	delete: (photographyId, config) =>
		destroy(`/photographies/${photographyId}`, config),
	search: (sort, page, pageSize, search, params, config) =>
		get(
			`/contests/?sort=${sort}&pageSize=${pageSize}&page=${page}&searchKey=${search}`,
			config
		),
};

export default Photography;
