import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
	fetchError,
	fetchStart,
	fetchSuccess,
	trySetUserPhotographies,
	setDeleteUserPhotography,
	setUserPhotographies,
	TRY_CREATE_PHOTOGRAPHY,
	TRY_DELETE_USER_PHOTOGRAPHY,
	TRY_SET_USER_PHOTOGRAPHIES,
} from 'redux/actions';

import { errorHandler } from 'utils/errorHandler';
import { getAccessToken, getAuthUser, getLastContest } from 'redux/selectors';
import Photography from 'services/endpoints/Photography';
import User from 'services/endpoints/User';

function* sagaCreatePhotography({ photographyData, resolve, reject }) {
	yield put(fetchStart());
	const lastContest = yield select(getLastContest);
	const accessToken = yield select(getAccessToken);
	if (!accessToken) {
		yield put(fetchSuccess());
		return false;
	}
	const headerParams = {
		Authorization: `Bearer ${accessToken}`,
	};

	try {
		const response = yield call(() =>
			Photography.create({ ...photographyData }, { headers: headerParams })
		);
		yield call(() => response.data);

		yield put(trySetUserPhotographies(lastContest.id));
		yield call(resolve);
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage, errors] = errorHandler(error);
		yield call(reject, errors);
		yield put(fetchError(errorMessage));
	}
}

function* sagaSetUserPhotographies({ lastContestId }) {
	yield put(fetchStart());
	const accessToken = yield select(getAccessToken);
	const lastContest = yield select(getLastContest);
	const authUser = yield select(getAuthUser);
	if (!accessToken) {
		yield put(fetchSuccess());
		return false;
	}
	const headerParams = {
		Authorization: `Bearer ${accessToken}`,
	};

	try {
		const response = yield call(() =>
			User.getPhotographies(authUser.userId, lastContestId, {
				headers: headerParams,
			})
		);

		const responseInfo = yield call(() => response.data);
		const categories = lastContest.categories.reduce(
			(acc, current) => ({
				...acc,
				[current.shortName]: 0,
			}),
			{}
		);
		responseInfo.forEach(response => {
			categories[response.category.shortName] += 1;
		});
		yield put(setUserPhotographies(responseInfo, responseInfo.length, categories));
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage] = errorHandler(error);
		yield put(fetchError(errorMessage));
	}
}

function* sagaDeleteUserPhotography({ id, categorySlug, resolve, reject }) {
	yield put(fetchStart());
	const accessToken = yield select(getAccessToken);
	if (!accessToken) {
		yield put(fetchSuccess());
		return false;
	}
	const headerParams = {
		Authorization: `Bearer ${accessToken}`,
	};

	try {
		yield call(() =>
			Photography.delete(id, {
				headers: headerParams,
			})
		);

		yield put(setDeleteUserPhotography(id, categorySlug));
		yield call(resolve);
		yield put(fetchSuccess());
	} catch (error) {
		const [errorMessage, errors] = errorHandler(error);
		yield call(reject, errors);
		yield put(fetchError(errorMessage));
	}
}

export function* photographySaga() {
	yield takeLatest(TRY_CREATE_PHOTOGRAPHY, sagaCreatePhotography);
	yield takeLatest(TRY_SET_USER_PHOTOGRAPHIES, sagaSetUserPhotographies);
	yield takeLatest(TRY_DELETE_USER_PHOTOGRAPHY, sagaDeleteUserPhotography);
}
