import assetService from '../services/assetService';
import { store } from 'App';
import { tryLogOut } from '../redux/actions';

export const errorHandler = error => {
	if (error) {
		if (Object.prototype.hasOwnProperty.call(error, 'code')) {
			if (error.code === 'ECONNABORTED') {
				return [
					'La conexión ha sido abortada, intente de nuevo por favor.',
					null
				];
			}
		}
	}
	if (error && error.message === 'Network Error') {
		return [assetService.sagas.error.connectionRefused, null];
	}
	if (error && error.response && error.response.data) {
		if (error.response.data.errors) {
			const errors = {};
			error.response.data.errors.forEach(err => {
				if (err.param === 'email_UNIQUE') {
					errors['email'] =
						'Esta dirección de correo electrónico ya está siendo utilizada. Prueba con otra.';
				} else {
					errors[err.param] = err.msg;
				}
			});
			return [error.response.data.message, errors];
		}

		if (error.response.data.code === 1000) {
			// Token has expired
			store.dispatch(tryLogOut());
		}
		return [error.response.data.message, null];
	}

	return []
};
