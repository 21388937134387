import { makeActionCreator } from 'utils';

export const TRY_REGISTER_USER = 'TRY_REGISTER_USER';
export const tryRegisterUser = makeActionCreator(
	TRY_REGISTER_USER,
	'userData',
	'resolve',
	'reject'
);

export const TRY_LOAD_USER_PROFILE = 'TRY_LOAD_USER_PROFILE';
export const tryLoadUserProfile = makeActionCreator(
	TRY_LOAD_USER_PROFILE,
	'userId'
);

export const SET_USER_PROFILE = 'SET_USR_PROFILE';
export const setUserProfile = makeActionCreator(
	SET_USER_PROFILE,
	'firstName',
	'lastName',
	'phone',
	'mobilePhone',
	'externalNumber',
	'internalNumber',
	'postalCode',
	'colonia',
	'street',
	'birthDate',
	'email',
	'username',
	'gender',
	'instagram',
	'twitter',
	'facebook',
	'lastLogin',
	'roleId',
	'municipalityId',
	'stateId',
	'municipality',
	'imTutor',
	'minorName',
	'verified',
	'verifiedAt',
	'createdAt',
	'updatedAt'
);

export const TRY_UPDATE_USER_PROFILE = 'SET_UPDATE_USER_PROFILE';
export const tryUpdateUserProfile = makeActionCreator(
	TRY_UPDATE_USER_PROFILE,
	'userId',
	'userData',
	'resolve',
	'reject'
);

export const SET_CLEAN_USER_PROFILE = 'SET_CLEAN_USER_PROFILE';
export const setCleanUserProfile = makeActionCreator(SET_CLEAN_USER_PROFILE);
